import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import * as S from "./InputStyled";
export default function Input({ name, label, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <S.Container>
      <S.Label>{label}</S.Label>
      <S.Input
        ref={inputRef}
        className={error ? "has-error" : ""}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && <S.MensageError className="error">{error}</S.MensageError >}
    </S.Container>
  );
}
