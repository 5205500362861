import React from "react";
import * as S from "./PhotoProfileDefaultStyled";

const PhotoProfileDefault = ({ name = "Teste Teste" }) => {
  // console.log(name.split(" ")[0].split("")[0]);
  const one = name.split(" ")[0].split("")[0].toUpperCase();

  // const two = name.split(" ")[1].split("")[0].toUpperCase();

  return (
    <S.Container>
      <span style={{ color: "#fff", fontSize: 13 }}>{one}</span>
      {/* {two && <span style={{ color: "#fff", fontSize: 13 }}>{two}</span>} */}
    </S.Container>
  );
};

export default PhotoProfileDefault;
